<template>
  <div class="login-container">
    <div class="container-login100 bg-slide">
      <span class="mask bg-gradient-primary opacity-6"></span>
      <div class="wrap-login100">
        <div class="login100-pic" data-tilt>
          <img :src="'/img/theme/card-p2.jpeg'" alt="IMG" />
        </div>

        <el-form
          :model="loginForm"
          status-icon
          :rules="loginRules"
          id="loginForm"
          ref="loginForm"
        >
          <span class="login100-form-title"> Manage Login </span>
          <el-form-item prop="username">
            <el-input
              prefix-icon="el-icon-user"
              type="text"
              v-model="loginForm.username"
              placeholder="Username"
              autocomplete="off"
              @keyup.enter.native="$refs.inputpassword.focus()"
            ></el-input>
          </el-form-item>
          <el-form-item prop="password">
            <el-input
              prefix-icon="el-icon-lock"
              type="password"
              v-model="loginForm.password"
              placeholder="Password"
              autocomplete="off"
              show-password
              ref="inputpassword" @keyup.enter.native="Login"
            ></el-input>
          </el-form-item>

          <el-form-item>
            <div class="container-login100-form-btn">
              <el-button
                :loading="loading"
                :disabled="loading"
                class="login100-form-btn"
                type="primary"
                @click="Login"
                >Login</el-button
              >
            </div>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import ManageApi from "@/api/manage";
export default {
  data() {
    return {
      loading: false,
      loginForm: {
        username: "",
        password: "",
        token: "",
      },
      loginRules: {
        username: [
          {
            required: true,
            message: "Please enter your username",
            trigger: "blur",
          },
        ],
        password: [
          {
            required: true,
            message: "Please enter your password",
            trigger: "blur",
          },
        ],
      },
    };
  },

  methods: {
    getServerToken() {
      ManageApi.getToken().then((res) => {
        if (res && res["result"]) {
          this.loginForm.token = res["data"]["token"];
        }
      });
    },
    Login() {
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          this.loading = true;
          this.$store.dispatch("manage/login", this.loginForm).then((res) => {
            this.loading = false;
            if (res && res.result) {
              this.$router.push('/manage/dashboard')
            } else {
              this.$swal("Message", res.message, "error").then(() => {
                this.getServerToken();
              });
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
  created() {
    this.getServerToken();
    if(this.$store.getters.token_manage){
        this.$router.push('/manage/dashboard')
    }
  },
};
</script>

<style lang="scss">
@import "~@/assets/scss/login.scss";
</style>